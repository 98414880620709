import "./Popup.css"

const PopupSignatureADistance = () => {

  const closePopup = () => {
    const popup = document.getElementById("cvc-popup")
    popup.style.display = "none"
  }

  return (
    <section className="cvc-popup">
      <div className="cvc-popup-container">
        <div className="cvc-popup-data-container">
          <img className="cvc-croix" src="/croix.png" alt="close" onClick={closePopup} loading="lazy"/>
          <div className="cvc-popup-data">
            <div className="cvc-popup-data-header">
              <p>deepblock.eu</p>
              <p>69 Place du Docteur Félix Lobligeois 75017 Paris - France</p>
            </div>
            <div className="cvc-popup-data-separator"/>
            <div className="cvc-popup-data-content">
              <div className="cvc-popup-data-content-left">
                <p>Bienvenue sur deepblock !</p>
                <p>
                  Bénéficiez de 3 cérémonies de
                  signatures offertes en saisissant
                  votre code CVC de Vivatech lors
                  de <a href={"/services?service-de-signature"}>l'inscription</a>.
                </p>
              </div>
              <div className="cvc-popup-data-content-right">
                <p>CVC :</p>
                <img src="/cvc-code.svg" alt="240509"/>
              </div>
            </div>
            <div className="cvc-popup-data-footer">
              <img src="/viva-popup.svg" alt="vivatech Technology"/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PopupSignatureADistance