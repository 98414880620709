import "./Actualite.css";
import {useContext, useEffect, useState} from "react";
import {ObjectContext} from "../../context/ObjectContext";
import {getStrapiData} from "../../request/strapi";
import Popup from "../../components/popup/Popup";

const Actualite = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [clicked, setClicked] = useState([]);
  const [arrayIsLoading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const [mediaLinks, setMediaLinks] = useState([]);

  const setFooterColor = useContext(ObjectContext).setFooterColor;

  useEffect(() => {
    setFooterColor("#e4e3d8")
    fetchActualiteElement();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setClickedArray()
    }
  }, [mediaLinks]);

  const fetchActualiteElement = async () => {
    const mediaLinks = [];
    await getStrapiData('actualite-deepblocks').then((response) => {
      setData(response.data.sort((a, b) => b.id - a.id));
      for (const article of response.data) {
        const mediaLinksTmp = [];
        if (article.attributes.Media.data !== null) {
          for (const media of article.attributes.Media.data) {
            mediaLinksTmp.push(API_URL + media.attributes.url);
          }
        }
        for (const mediaUrl of article.attributes.Lien_media.split(' ')) {
          if ((mediaUrl.includes('youtube.com') || mediaUrl.includes('youtu.be'))) {
            mediaLinksTmp.push(mediaUrl);
          }
        }
        mediaLinksTmp.id = article.id;
        mediaLinks.push(mediaLinksTmp);
      }
      setMediaLinks(mediaLinks);
      setIsLoading(false);
    }).catch((error) => {
      console.error('Erreur lors de la récupération des données:', error);
      setIsLoading(false)
    });
  }

  const setClickedArray = () => {
    const newClicked = [];
    for (let i = 0; i < data.length; i++) {
      if (mediaLinks[i].length > 1) {
        const subClicked = [];
        for (let j = 0; j < mediaLinks[i].length; j++) {
          subClicked.push(false);
        }
        newClicked.push(subClicked);
      } else {
        newClicked.push(false);
      }
    }
    setClicked(newClicked);
    setLoading(false)
  }

  const handleClick = (articleIndex, articleSubIndex = -1) => {
    const newClicked = [...clicked];
    if (articleSubIndex !== -1) {
      newClicked[articleIndex][articleSubIndex] = true;
    } else {
      newClicked[articleIndex] = true;
    }
    setClicked(newClicked);
  };

  const actualiteElement = () => {
    if (data === undefined) {
      if (isLoading) {
        return <p>Chargement en cours...</p>;
      }
      return <p>Aucune actualité trouvée</p>;
    }

    if (arrayIsLoading) {
      return <p>Chargement en cours...</p>;
    }

    return (
      data.sort((a, b) => b.id - a.id).map((article, articleIndex) => {
        return (
          <div className="article" key={article.id}>
            <div className="mobile-article-text">
              <h4 className="article-Titre">{article.attributes.Titre || 'Titre indisponible'}</h4>
            </div>
            <a className={article.attributes.Lien ? 'a-article' : 'a-article no-cursor'}
               href={article.attributes.Lien || '#'}
               target={article.attributes.Lien ? '_blank' : ''}
               onClick={(event) => {
                 if (!article.attributes.Lien) {
                   event.preventDefault();
                 }
               }}
            >
              {mediaElement(article, articleIndex)}
              <div className="article-Text">
                <h4 className="article-Titre">{article.attributes.Titre || 'Titre indisponible'}</h4>
                <p className="article-Description">{article.attributes.Text || ''}</p>
              </div>
            </a>
            <div className="mobile-article-text">
              <p className="article-Description">{article.attributes.Text || ''}</p>
            </div>
          </div>
        );
      })
    );
  };

  const mediaElement = (article, articleIndex) => {
    return (
      mediaLinks[articleIndex].length > 1 ? (
        sliderElement(article, articleIndex)
      ) : (
        singleMediaElement(article, articleIndex)
      )
    )
  }

  const sliderElement = (article, articleIndex) => {
    return (
      <div className={`slider-container slider-${articleIndex}`} key={"slider-"+articleIndex}>
        {
          (article.attributes.Lien_media ? article.attributes.Lien_media.split(' ') : []).map((mediaUrl, mediaIndex) => {
            if (mediaUrl.includes('youtube.com') || mediaUrl.includes('youtu.be')) {
              const youtubeId = mediaUrl.split('v=')[1] || mediaUrl.split('/').pop();
              {
                return (
                  <div className={"img-slide"}
                       key={articleIndex+"-"+mediaIndex}
                       style={{display: mediaIndex === 0 ? 'block' : 'none'}}>
                    {
                      clicked[articleIndex][mediaIndex] ? (
                        <iframe
                          key={mediaIndex}
                          src={`https://www.youtube.com/embed/${youtubeId}`}
                          className="youtube-video"
                          allowFullScreen
                          style={{width: "100%", height: "100%", objectFit: "contain"}}
                        />
                      ) : (
                        <div>
                          {
                            article.attributes.Miniature.data !== null ?
                              <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                                   src={API_URL + article.attributes.Miniature.data.attributes.formats.medium.url}
                                   alt="Miniature vidéo"
                                   onClick={() => handleClick(articleIndex)}
                              />
                            :
                              <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                                   src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                                   alt="preview image for youtube video"
                                   onClick={() => handleClick(articleIndex, mediaIndex)}
                              />
                          }

                          <img src={"/play-button.png"} className="play-button" onClick={() => handleClick(articleIndex, mediaIndex)}/>
                        </div>
                      )
                    }
                  </div>
                )
              }
            } else {
              return (
                <img key={mediaIndex} src={mediaUrl} className="img-slide"
                     style={{display: mediaIndex === 0 ? 'block' : 'none'}}/>
              );
            }
          })
        }
        <button className="slider-button" onClick={(event) => {
          event.preventDefault();
          changeSlide(document.querySelector(`.slider-${articleIndex}`), -1);
        }}>
          <img src="/fleche-gauche.png" alt="Précédent" className="slider-arrow" loading="lazy"/>
        </button>
        <button className="slider-button" onClick={(event) => {
          event.preventDefault();
          changeSlide(document.querySelector(`.slider-${articleIndex}`), 1);
        }}>
          <img src="/fleche-droite.png" alt="Suivant" className="slider-arrow" loading="lazy"/>
        </button>
      </div>
    )
  }

  const singleMediaElement = (article, articleIndex) => {
    return (
      (article.attributes.Lien_media ? article.attributes.Lien_media.split(' ') : []).length === 1 ? (
        <div className={"mobile-adapt"} key={"mobile-"+articleIndex}>
          {
            (article.attributes.Lien_media ? article.attributes.Lien_media.split(' ') : []).map((mediaUrl, mediaIndex) => {
              if (mediaUrl.includes('youtube.com') || mediaUrl.includes('youtu.be')) {
                const youtubeId = mediaUrl.split('v=')[1] || mediaUrl.split('/').pop();
                return (
                  <div className={"article-video youtube-video"} key={articleIndex+"-"+mediaIndex}>
                    {
                      clicked[articleIndex] ? (
                        <iframe
                          key={mediaIndex}
                          src={`https://www.youtube.com/embed/${youtubeId}`}
                          className="article-video youtube-video"
                          allowFullScreen
                          style={{width: "100%", height: "100%", objectFit: "cover"}}
                        />
                      ) : (
                        <div style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}>
                          {
                            article.attributes.Miniature.data !== null ?
                              <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                                   src={API_URL + article.attributes.Miniature.data.attributes.formats.medium.url}
                                   alt="Miniature vidéo"
                                   onClick={() => handleClick(articleIndex)}
                              />
                              :
                              <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                                   src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                                   alt="preview image for youtube video"
                                   onClick={() => handleClick(articleIndex, mediaIndex)}
                              />
                          }
                          <img src={"/play-button.png"} className="play-button" onClick={() => handleClick(articleIndex, mediaIndex)}/>
                        </div>
                      )
                    }
                  </div>
                )
              } else if (mediaUrl.match(/\.(jpeg|jpg|gif|png)$/)) {
                return (
                  <img key={mediaIndex} src={mediaUrl} alt="Image indisponible" className="article-img" loading="lazy"/>
                );
              } else if (mediaUrl.match(/\.(mp4|webm|ogg)$/)) {
                return (

                  clicked[articleIndex] ? (
                    <video key={mediaIndex} src={mediaUrl} controls autoPlay className="article-video"/>
                  ) : (
                    <div style={{width: "600px", height: "auto", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}>
                      <img style={{width: "100%", height: "100%", objectFit: "cover", cursor: "pointer", position: "relative", top: "0", left: "0"}}
                           src={API_URL + article.attributes.Miniature.data.attributes.formats.medium.url}
                           alt="Miniature vidéo"
                           onClick={() => handleClick(articleIndex)}
                      />
                      <img src={"/play-button.png"} className="play-button" onClick={() => handleClick(articleIndex, mediaIndex)}/>
                    </div>

                  )
                );
              }
              return null;
            })
          }
        </div>
      ) : null
    )
  }

  const changeSlide = (sliderContainer, direction) => {
    const slides = sliderContainer.getElementsByClassName('img-slide');
    let currentIndex = -1;

    // Trouver l'image actuellement visible
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].style.display === 'block') {
        currentIndex = i;
        break;
      }
    }

    // Cacher l'élément actuel puis afficher le nouveau
    slides[currentIndex].style.display = 'none';
    const newIndex = (currentIndex + direction + slides.length) % slides.length;
    slides[newIndex].style.display = 'block';
  };

  return (
    <div className="actualite-container">

      <Popup/>

      <section className="actualite-intro">
        <div className="actualite-intro-left">
          <p className="actualite-intro-title">Retrouvez toutes <span className="jaune">nos actualités</span> au même endroit.</p>
          <p className="actualite-intro-description">Le réel bonheur est de faire d'une passion un métier.</p>
        </div>
      </section>

      <section className="actualite-main">
        <div id="articles">
          {actualiteElement()}
        </div>
      </section>
    </div>
  );
}

export default Actualite;