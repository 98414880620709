import "./Footer.css"
import MentionsLegal from "../mentions-legal/mentions-legal";
import React, { useState, useEffect } from "react";

const Footer = () => {

  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  const openPopup = () => {
      document.getElementById("popup-footer").style.display = "flex";
  }

  const closePopup = () => {
    document.getElementById("popup-footer").style.display = "none";
  }

  return (
    <div className="footer-container">

      <section id="popup-footer" className="popup-footer" style={{ display: 'none' }}>
        <div className="popup-footer-container">
          <img className="croix-footer" src="/croix.png" alt="close" onClick={closePopup} loading="lazy"/>
          <div className="popup-footer-data">
            <MentionsLegal/>
          </div>
        </div>
      </section>

      <img src="/footer-separator.png" className="footer-img-separator" width="355px" alt={"Image décoratif de transition entre le corps de lage page et le footer"}/>
      <div className="footer-content">
        <div className="footer-grid">
          <div className="footer-contact">
            <div className="mobile-footer-logo">
              <a href="https://www.deepblock.eu/" rel="noopener noreferrer" target="_self">
                <img src="/Logo.svg" alt="logo" loading="lazy"/>
              </a>
            </div>
            <div className="footer-contact-text-1">
              <span>Prêts à vous lancer ?</span>
            </div>
            <div className="footer-contact-text-2">
              <span>Discutons-en</span>
            </div>
            <div className="footer-contact-text-3">
            {window.innerWidth <= 768 ? (
              <span><a href="tel:+33781356115" x-autocompletetype="off" autocomplete="off">+33 7 81 35 61 15</a></span>
            ) : (
              <span>+33 7 81 35 61 15</span>
            )}
            </div>
            <div className="footer-contact-text-4">
            {window.innerWidth <= 768 ? (
              <span><a x-autocompletetype="off" autocomplete="off">contact@deepblock.eu</a></span>
            ) : (
              <span>contact@deepblock.eu</span>
            )}
            </div>
            <a href="/contact" rel="noopener noreferrer" target="_self" className="footer-contact-button">
              <div>Contactez-nous</div>
            </a>
            <div className="footer-contact-social">
              <a href="https://www.linkedin.com/company/deep-block/about/" rel="noopener noreferrer" target="_blank">
                <img src="/linkedin.svg" alt="linkedin" loading="lazy"/>
              </a>
              <a href="https://www.tiktok.com/@deepblock.eu" rel="noopener noreferrer" target="_blank">
                <img src="/tiktok.svg" alt="twitter" loading="lazy"/>
              </a>
              <a href="https://x.com/deepblock_sas?lang=fr" rel="noopener noreferrer" target="_blank">
                <img src="/X.svg" alt="youtube" loading="lazy"/>
              </a>
            </div>
          </div>

          <div className="footer-navbar">
            <section className="footer-navbar-section">
              <div className="footer-logo">
                <a href="https://www.deepblock.eu/" rel="noopener noreferrer" target="_self">
                  <img src="/Logo.svg" alt="logo" loading="lazy"/>
                </a>
              </div>
              <div className="footer-navbar-link">
                <a href={"/"}>Services</a>
                <a href={"/"}>Technologies</a>
                <a href={"/ressources"}>Ressources</a>
                <a href={"/actualite"}>Actualités</a>
                <a href={"/a-propos"}>Nous connaître</a>
              </div>

              <div className="footer-legal">
                <div>
                  <a href="/affiliation">
                    AFFILIATIONS
                  </a>
                </div>
                <div>
                  <a onClick={openPopup}>
                    INFORMATIONS LÉGALES
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="footer-info">
          <span>©deepblock SAS {currentYear}. Tous droits réservés. | 69 Place du Docteur Félix Lobligeois 75017 Paris (France)</span>
        </div>
      </div>
    </div>
  )
}

export default Footer